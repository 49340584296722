@import '~csshake/scss/csshake-hard.scss';

// .my-custom-shake {
//   @include do-shake(
//     $name: 'my-custom-shake',
//     $h: 5px,
//     $v: 1px,
//     $r: 3deg,
//     $dur: 10000ms,
//     $precision: 0.02,
//     $opacity: false,
//     $q: infinite,
//     $t: ease-in-out,
//     $delay: 0,
//     $chunk: 100%
//   );
// }

@for $i from 0 through 9 {
  .my-custom-shake-#{$i} {
    @include do-shake(
      $name: 'my-custom-shake-#{$i}',
      $h: 1px,
      $v: 1px,
      $r: 2deg,
      $dur: 8000ms,
      $precision: 0.01,
      $opacity: false,
      $q: infinite,
      $t: ease-in-out,
      $delay: 0,
      $chunk: 100%
    );
  }
}
