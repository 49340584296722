@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  min-height: 100vh;
  /* mobile viewport bug fix */

  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.react-grid-item.react-grid-placeholder {
  background: none !important;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  background-image: none;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se::after {
  width: 8px !important;
  height: 8px !important;
  z-index: 1000 !important;
  background-image: none;

  border-color: black;
  margin-bottom: 2px;
  margin-right: 4px;
  top: 25px;
  left: 5px;
}
/* @media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    
    Define here the CSS styles applied only to the Safari 9 version when
    running in iOS devices (iPhone, iPad, ...)
   
    .safari-ios-drawer {
      margin-top: 10rem;
    }
  }
} 
*/

/* remove focus outline */
:focus {
  outline: 0 !important;
}

.creatable-select__input {
  width: auto !important;
}

.sidebar-shadow {
  background: -moz-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(237, 242, 247)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(237, 242, 247, 0.267) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C */
  bottom: -16px;
  height: 16px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 1;
}

.gradient {
  position: relative;
}

.gradient:before {
  position: absolute;
  content: ' ';
  top: 0px;
  width: 80%;
  height: 20px;
  z-index: 1;
  background: -moz-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(237, 242, 247)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(237, 242, 247, 0.267) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(237, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EDF2F7', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
}
/* 
.creatable-select-chakra-styles__option:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.creatable-select-chakra-styles__option:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
} */

.embeddedTweet {
  color: aqua !important;
}

.article[style*='color: red'] {
  color: white !important;
}

.r-vqp9x9 {
  background-color: #000000 !important;
}

.r-1q9bdsx {
  border-radius: 0 !important;
}
