.AddWidgetOption:hover .AddWidgetOption__details,
.AddWidgetOption:active .AddWidgetOption__details {
  transform: translate3d(0, 0%, 0);
}

.AddWidgetOption:hover .AddWidgetOption__cover,
.AddWidgetOption:active .AddWidgetOption__cover {
  transform: translate3d(0, -100%, 0);
}

.AddWidgetOption__cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 1%;
  left: 0;
  transition: transform 300ms;
  transform: translate3d(0, 0, 0);
  /* box-shadow: ; */
  /* border-width: 2px; */
  cursor: pointer;
}

.AddWidgetOption__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 10px;
  transition: transform 300ms;
  transform: translate3d(0, 100%, 0);
  cursor: pointer;
  /* border-width: 2px; */
}
